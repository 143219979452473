var render = function render(){var _vm=this,_c=_vm._self._c;return _c('book-cover',{ref:"BookCover"},[_c('v-list-item-title',{staticClass:"content_card_title text-center mb-3"},[_c('b',[_vm._v(_vm._s(_vm.$t("cardTitle.assets")))])]),(_vm.loading)?_c('div',{staticStyle:{"text-align":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#6166f5"}})],1):_c('div',{staticClass:"info_layout"},[(_vm.asset.isSale)?_c('h4',{staticClass:"text-center green--text mb-1"},[_vm._v(" "+_vm._s(_vm.$t("common.forSale"))+" ")]):_vm._e(),_c('h2',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.asset.assetName)+" ")]),(_vm.asset.type == _vm.AssetType.Consumable)?_c('v-row',{staticClass:"name__detail mt-2 green--text"},[_c('h3',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("asset.instock"))+": "+_vm._s(_vm.asset.amount))])]):_vm._e(),(_vm.showQr)?_c('v-row',{staticClass:"avatar__detail mt-1"},[_c('vue-qr',{attrs:{"text":_vm.asset.assetQrUri,"size":200}})],1):_vm._e(),_c('v-row',{staticClass:"avatar__detail mt-3"},[_c('v-btn',{attrs:{"small":"","elevation":"0","color":"green","outlined":""},on:{"click":function($event){_vm.showQr = !_vm.showQr}}},[_vm._v(_vm._s(_vm.showQr ? _vm.$t("asset.hideQr") : _vm.$t("asset.showQr")))])],1),_c('v-list-item',{staticClass:"pr-2 pl-2 mb-5 mt-5"},[_c('v-divider')],1),_c('div',{staticClass:"pl-3 pr-3 mb-5"}),_c('div',{staticClass:"pl-3 pr-3"},[(!_vm.$isPatient)?_c('p',{staticClass:"mb-5"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("mdi-image-multiple-outline")]),_c('b',[_vm._v(_vm._s(_vm.$t("common.galery"))+":")])],1):_vm._e(),(
          !_vm.$isPatient &&
          _vm.asset.clinicID &&
          _vm.$clinicID.toLowerCase() == _vm.asset.clinicID.toLowerCase()
        )?_c('image-galery',{attrs:{"targetItemID":_vm.asset.assetID,"targetItemType":_vm.TargetItemType.Asset}}):_vm._e(),(
          !_vm.$isPatient &&
          _vm.asset.clinicID &&
          _vm.$clinicID.toLowerCase() == _vm.asset.clinicID.toLowerCase()
        )?_c('div',{staticClass:"mt-5"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("mdi-tag-multiple-outline")]),_c('b',[_vm._v(_vm._s(_vm.$t("common.category"))+":")]),_c('div',{staticClass:"mt-0"},[_c('category-selector',{attrs:{"targetItemID":_vm.asset.assetID,"targetItemType":_vm.TargetItemType.Asset}})],1)],1):_vm._e(),_c('p',{staticClass:"mt-5"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("mdi-clipboard-file-outline")]),_c('b',[_vm._v(_vm._s(_vm.$t("asset.type"))+":")]),_vm._v(" "+_vm._s(_vm.asset.typeName)+" ")],1),_c('p',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("mdi-file-code-outline")]),_c('b',[_vm._v(_vm._s(_vm.$t("asset.assetCode"))+":")]),_vm._v(" "+_vm._s(_vm.asset.assetCode)+" ")],1),_c('p',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("mdi-cash")]),_c('b',[_vm._v(_vm._s(_vm.$t("asset.price"))+":")]),_c('b',{staticClass:"green--text ml-1"},[_vm._v(_vm._s(_vm.asset.price))])],1),(_vm.asset.saleDescription)?_c('div',{staticClass:"mb-3"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("mdi-file-code-outline")]),_c('b',[_vm._v(_vm._s(_vm.$t("common.saleHighlight"))+":")]),_c('div',{staticClass:"mt-3 mb-3 editor_result_content",domProps:{"innerHTML":_vm._s(_vm.asset.saleDescription)}})],1):_vm._e(),(_vm.asset.description)?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("mdi-clipboard-text-outline")]),_c('b',[_vm._v(_vm._s(_vm.$t("asset.description"))+":")]),_c('div',{staticClass:"mt-3 mb-3 editor_result_content",domProps:{"innerHTML":_vm._s(_vm.asset.description)}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }